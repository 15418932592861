import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'
import logo from '../../content/images/logo.svg'

class NotFound extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="ページが見つかりません" />
        <header className="jumbotron rounded-0">
          <div className="container mw7">
            <h1 className="display-3 font-weight-bold">404</h1>
            <p className="mb-0 font-weight-bold">
              お探しのページが見つかりませんでした。
            </p>
          </div>
        </header>

        <section>
          <div className="container mw7 py-3">
            <p className="h4">
              せっかくご訪問いただきましたが、アクセスしようとしたページは削除されたかURLが変更されています。
            </p>
            <p>
              大変申し訳ないのですが、あなたがアクセスしようとしたページは削除されたかURLが変更されています。お手数をおかけしますが、
              <a href="/">トップページ</a>
              からもう一度目的のページをお探し下さい。
            </p>
            <div className="text-center">
              <a href="/" className="btn btn-outline-primary mt-4 btn-lg">
                トップページへ
              </a>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default NotFound
